import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from "react-device-detect";
import {
    Box,
    Paper,
    Typography,
    Button,
    Grid,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Card,
    CardContent,
    Table,
    Dialog,
    DialogContent,
    DialogActions,
    TextField
} from "@mui/material";
import ReactGA from "react-ga4";
import TrainingForm from "../components/TrainingForm.jsx";
import {
    CalendarMonthOutlined as CalendarMonthOutlinedIcon,
    Category as CategoryIcon,
    CastForEducation as CastForEducationIcon,
    UpdateOutlined as UpdateOutlinedIcon,
    MonetizationOnOutlined as MonetizationOnOutlinedIcon,
    PeopleAltOutlined as PeopleAltOutlinedIcon,
    PermIdentityRounded as PermIdentityRoundedIcon,
    EastOutlined as EastOutlinedIcon,
    BorderBottom
} from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import theme from '../theme.jsx';

const Trainingcourses = (props) => {
    const { globalUrl, userdata, isCloud } = props;
    const [selected, setSelected] = useState(0)
    const [availableSlots, setAvailableSlots] = useState(10);
    const [availableSlotsPerDate, setAvailableSlotsPerDate] = useState({});
    const [fromTrainingPage] = useState(true)
    const [showTrainingForm, setShowTrainingForm] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [firstname, setFirstname] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [trainingRequest, setTrainingRequest] = useState("Public Training");
    const backgroundStyle = {
        backgroundImage: "url('images/course_page/course.svg')",
        backgroundPosition: "center",
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundRepeat: "no-repeat",
        width: "100%",
        bottom: "33%",
        top: "-36%",
        height: "100%",
        position: "absolute",
        zIndex: 1,
        animation: "moveBackground 5s linear infinite",
    };
    const backgroundStyle1 = {
        backgroundImage: "url('images/course_page/course1.svg')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "50%",
        position: "absolute",
        zIndex: 1,
        animation: "moveBackground 5s linear infinite",
    };
    const bookSeatRef = useRef(null);
    const scrollFormRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    const buttonBackground = "linear-gradient(to right, #f86a3e, #f34079)"

    const createData = (courseName, time, language, timezone, instructor, originalPrice, discountedPrice) => {
        return { courseName, time, language, timezone, instructor, originalPrice, discountedPrice };
    };

    const rows = [
        // createData('Automation for Security Professionals', 'Aug 12 - Aug 13', 'Virtual', '10am - 4pm CET', 'David Nyerere / Shuffle Instructor', '$1000', '$799'),
        // createData('Automation for Security Professionals', 'Aug 26 - Aug 27', 'Virtual', '10am - 4pm EST', 'Mark Michael / Shuffle Instructor', '$1000', '$799'),
        // createData('Automation for Security Professionals', '-', 'Virtual / In Person', '-', '-', '-', ''),
        // Add more rows as needed

        // createData('Automation for Security Professionals', 'TBD', 'Virtual', 'TBD', 'David Nyerere / Shuffle Instructor', '$1000', '$799'),
        createData('Automation for Security Professionals', 'Feb 10-11', 'Virtual', '10am to 4pm EST', 'Mark Michael', '$999', '$500'),
        createData('Automation for Security Professionals', '-', 'Virtual / In Person', '-', '-', '-', ''),
    ];

    const cards = [
        "Shuffle Platform", "Apps", "Workflows", "Usecases", "Networking",
        "APIs", "Data Flow", "JSON", "Liquid Formatting"
    ];

    const availableTimes = rows.filter(row => row.time !== 'TBD').map(row => row.time);

    useEffect(() => {
        if (shouldScroll && scrollFormRef.current) {
            scrollFormRef.current.scrollIntoView({ behavior: 'smooth' });
            setShouldScroll(false); // Reset the scroll trigger
        }
        const initialAvailableSlots = {};
        rows.forEach(row => {
            initialAvailableSlots[row.time] = 10; //the actual initial slots available for each date
        });
        setAvailableSlotsPerDate(initialAvailableSlots);
    }, [shouldScroll]);

    const handleButtonClick = () => {
        window.open("https://book.stripe.com/eVa2bg9DU8dsces4gj", "_blank");
        if (isCloud) {
            ReactGA.event({
                category: "training",
                action: "book_seat_link_clicked",
                label: "",
            })
        }
    };

    const handleContactUsClick = () => {
        window.location.href = `mailto:shuffle-support@shuffler.io?subject=Request%20for%20Private%20Training&body=Please%20share%20details%20for%20training%20such%20as%20company%20details,%20number%20of%20trainees,%20dates,%20and%20other%20notes.%20We%20will%20be%20in%20touch%20:)`;
    };

    const handleContactUs = () => {

        const validateEmail = (email) => {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(String(email).toLowerCase());
        };

        if (!validateEmail(email)) {
            toast.error("Please enter a valid email address.");
            return;
        }

        const data = {
            firstname: firstname,
            email: email,
            message: message
        }

        fetch(`${globalUrl}/api/v1/contact?training=${trainingRequest}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => {
            if (response.status === 200) {
                toast.success("Thanks for reaching out. We will contact you soon!");
                setShowContactForm(false);
                setFirstname("");
                setEmail("");
                setMessage("");
            } else {
                toast.error("Failed to send message. Please try again later.");
            }
        }).catch((error) => {
            console.error('Error:', error);
            toast.error("Failed to send message. Please try again later.");
        });
    };
    // const handleBooking = (date) => {
    //     const totalAvailableSlots = availableSlotsPerDate[date] || 0;
    //     if (totalAvailableSlots > 0) {
    //         const updatedAvailableSlots = { ...availableSlotsPerDate };
    //         updatedAvailableSlots[date] -= 1;
    //         setAvailableSlotsPerDate(updatedAvailableSlots);
    //         console.log(`Seat booked successfully for date: ${date}`);

    //     } else {
    //         console.log(`Join the waiting list for date: ${date}`);
    //     }
    // };

    return (
        <div style={{ height: "auto", backgroundColor: "#1a1a1a" }}>
            <div>
                <div style={{ fontFamily: "inter" }} >
                    <span style={backgroundStyle} />
                    {/* <div style={{ zIndex: 1, padding: 50 }}>
                        <Grid container spacing={2} style={{ justifyContent: "center", alignItems: "center", zIndex: 2, flexWrap: isMobile ? null : "nowrap", marginTop: 100 }}>
                            <Grid item style={{ textAlign: isMobile ? "left" : "left", marginRight: isMobile ? null : 25 }} >

                                <Typography style={{ fontSize: 35, textTransform: "capitalize", color: "white", fontWeight: 500 }}>
                                    Become a Shuffle Expert
                                </Typography>

                                <Typography style={{ maxWidth: 500, marginTop: 15, fontSize: 20, fontWeight: 400 }}>
                                    Our courses are tailored to provide hands-on learning of Shuffle for
                                    automation mastery. Whether you're new to Shuffle or an experienced user, our courses
                                    are designed to help you get the most out of our platform. Lean more below:
                                </Typography>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        bookSeatRef.current.scrollIntoView({ behavior: "smooth" });
                                    }}
                                    style={{ color: "white", width: 247, borderRadius: 200, backgroundImage: buttonBackground, zIndex: 10, marginTop: 20, fontWeight: 500, textAlign: "left", textTransform: "capitalize", fontSize: 16 }}
                                >
                                    Book your seat
                                </Button>

                            </Grid>
                            <Grid item style={{ maxWidth: '100%', zIndex: 2 }}>
                                <img src='images/course_page/frontCourse.svg' style={{ marginTop: isMobile ? 50 : null, width: isMobile ? 280 : null }} />
                            </Grid>
                        </Grid>
                    </div> */}
                </div>
                {/* <div style={{ border: '2px solid #FF8444', width: '50%', borderRadius: 200, margin: '0 auto' }} /> */}
                <div style={{ position: "relative", marginTop: 80, }}>
                    <Grid container spacing={10} style={{ textAlign: "center", justifyContent: "center", padding: 50, flexWrap: isMobile ? null : "nowrap", flexDirection: isMobile ? null : "row-reverse", alignItems: "center" }}>
                        <Grid item style={{ textAlign: "left", zIndex: 3, }} >
                            <Typography style={{ fontWeight: 500, marginTop: 50, textTransform: "capitalize", color: "white", fontSize: 32, display: isMobile ? null : "flex" }}>
                                Become&nbsp;<span style={{ textTransform: "lowercase" }}>a</span>&nbsp;Shuffle Expert!
                            </Typography>
                            {/* <Typography style={{ fontWeight: 400, maxWidth: 500, marginTop: 20, fontSize: 16 }}>
                                At Shuffle, security takes precedence. We believe in empowering our users through a hands-on experience,
                                offering a unique opportunity to explore our platform before making any commitments.
                                <br /><br />
                                With a comprehensive suite of automation tools at your disposal,
                                we enable you to streamline your operations efficiently.
                                <br /><br />
                                But that's not all. We go beyond the basics,
                                assisting you in identifying use cases that align perfectly with your individual requirements.
                                Accessibility lies at the heart of our mission, and our aim is to ensure that every Security Operations Center (SOC) around the globe can leverage and exchange
                                their use cases seamlessly.
                            </Typography> */}
                            <Typography style={{ fontWeight: 400, maxWidth: 500, marginTop: 20, fontSize: 20 }}>
                                Our courses are tailored to provide hands-on learning of Shuffle for
                                automation mastery. Whether you're new to Shuffle or an experienced user, our courses
                                are designed to help you get the most out of our platform. <br /><br />Learn more below:
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    window.open('/images/shuffle_training_syllabus.pdf', '_blank');
                                    if (isCloud) {
                                        ReactGA.event({
                                            category: "training",
                                            action: "download_syllabus_clicks",
                                            label: "",
                                        })
                                    }
                                }}
                                style={{ color: "white", width: 247, borderRadius: 200, backgroundImage: buttonBackground, zIndex: 10, marginTop: 20, fontWeight: 500, textAlign: "left", textTransform: "capitalize", fontSize: 16 }}
                            >
                                Download Syllabus
                            </Button>
                        </Grid>
                        <Grid item style={{ zIndex: 2, marginTop: 50, }}>
                            <img src='images/course_page/knowshuffle.svg' style={{ width: '80%', }} />
                        </Grid>
                    </Grid>
                </div>
                <div style={{ minWidth: isMobile ? null : 1000, maxWidth: isMobile ? null : 1000, margin: "auto", position: "relative", height: "auto", marginTop: 80, marginBottom: isMobile ? 80 : 10, zIndex: 10, }}>
                    <Typography style={{ textAlign: "center", textTransform: "capitalize", margin: "auto", color: "white", fontSize: 32 }}>
                        Training options
                    </Typography>
                    <Grid container spacing={2} style={{ display: isMobile ? null : "flex", flexWrap: isMobile ? "nowrap" : "nowrap", flexDirection: isMobile ? "column" : null, minWidth: isMobile ? null : 1000, maxWidth: isMobile ? null : 1000, margin: isMobile ? null : "auto", alignItems: isMobile ? "center" : null, position: "relative", height: 275, marginTop: 30, zIndex: 10, }}>
                        <Grid item xs={8} style={{ borderRadius: 16, padding: 24, backgroundColor: "#212121", height: "auto", width: isMobile ? "100%" : null }}>
                            <div style={{ width: 50, height: 50, borderRadius: 200, backgroundColor: "#FF8444", textAlign: "center", alignContent: "center" }}>
                                <PeopleAltOutlinedIcon />
                            </div>
                            <Typography style={{ fontWeight: 550, marginTop: 20, marginBottom: 14, color: "#ffffff", fontSize: 24 }}>
                                Public Course
                            </Typography>
                            <Typography style={{ fontSize: 16, color: "#C8C8C8" }}>
                                Join our 2-day online course with a skilled Shuffle
                                instructor. Learn practical skills over 6 hours daily with your own lab setup. Plus, get an
                                extra day for hands-on practice after the lectures. Become a Shuffle Expert now!
                            </Typography>
                            {/* <Link onClick={() => {
                                handleButtonClick()
                            }} style={{ display: "flex", fontSize: 18, justifyContent: "end", alignItems: "center", marginTop: 20, color: "#FF8444", textDecoration: "none" }}>
                                Book now <EastOutlinedIcon style={{ marginLeft: 5 }} />
                            </Link> */}
                        </Grid>
                        <Grid item xs={8} style={{ marginTop: isMobile ? 25 : null, marginLeft: isMobile ? null : 17, borderRadius: 16, padding: 24, backgroundColor: "#212121", height: "auto" }}>
                            <div style={{ width: 50, height: 50, borderRadius: 200, backgroundColor: "#FB47A0", textAlign: "center", alignContent: "center" }}>
                                <PermIdentityRoundedIcon />
                            </div>
                            <Typography style={{ fontWeight: 550, marginTop: 20, marginBottom: 14, color: "#ffffff", fontSize: 24 }}>
                                Private Course
                            </Typography>
                            <Typography style={{ fontSize: 16, color: "#C8C8C8", width: isMobile ? null : 400 }}>
                                We provide private training programs as well for a
                                minimum of 5 attendees. We work closely with customers to tailor and schedule them as
                                per their needs.
                            </Typography>
                            {/* <Link onClick={() => { handleContactUsClick() }} style={{ display: "flex", fontSize: 18, justifyContent: "end", alignItems: "center", marginTop: 20, color: "#FB47A0", textDecoration: "none" }}>
                                Contact us <EastOutlinedIcon style={{ marginLeft: 5 }} />
                            </Link> */}
                        </Grid>
                    </Grid>
                </div>
                <div style={{ minWidth: isMobile ? '100%' : 1000, maxWidth: isMobile ? '100%' : 1000, margin: "auto", position: "relative", height: "auto", marginTop: isMobile ? 490 : 90, marginBottom: isMobile ? 80 : 10, zIndex: 10 }}>
                    <Typography style={{ textAlign: "center", textTransform: "capitalize", margin: "auto", color: "white", fontSize: isMobile ? 24 : 32 }}>
                        Training Covers
                    </Typography>
                    <Grid container spacing={4} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: "center",
                        margin: 'auto',
                        alignItems: 'flex-start',
                        position: 'relative',
                        height: 'auto',
                        width: isMobile ? 300 : null,
                        marginTop: isMobile ? 30 : 30,
                        zIndex: 10,
                    }}>
                        {cards.map((card, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index} style={{
                                borderRadius: 16,
                                padding: 14,
                                backgroundColor: "#212121",
                                height: 'auto',
                                textAlign: "center",
                                marginBottom: 16,
                                margin: 8
                            }}>
                                <Typography style={{
                                    fontWeight: 550,
                                    marginTop: 20,
                                    marginBottom: 14,
                                    color: "#ffffff",
                                    fontSize: 18
                                }}>
                                    {card}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <div ref={bookSeatRef} style={{ minWidth: isMobile ? null : 1000, maxWidth: isMobile ? null : 1000, margin: "auto", position: "relative", height: "auto", marginTop: isMobile ? 120 : 80, zIndex: 10, }}>

                    <Typography style={{ textAlign: "center", textTransform: "capitalize", margin: "auto", marginBottom: 25, paddingLeft: isMobile ? null : null, color: "white", fontSize: 32 }}>
                        Availability
                    </Typography>
                    {isMobile ?
                        <Grid container style={{}}>
                            <Grid item style={{ width: isMobile ? "100%" : "auto", padding: 20, }}>
                                {rows.map((row, index) => {
                                    const date = row.time;
                                    const availableSlots = availableSlotsPerDate[date] || 0;
                                    const isSlotAvailable = availableSlots > 0;
                                    const handleClick = () => {
                                        if (row.originalPrice === "-") {
                                            if (isCloud) {
                                                ReactGA.event({
                                                    category: "training",
                                                    action: "click_on_bookseat_private",
                                                    label: "",
                                                })
                                            }
                                            window.location.href = `mailto:shuffle-support@shuffler.io?subject=Request%20for%20Private%20Training&body=Please%20share%20details%20for%20training%20such%20as%20company%20details,%20number%20of%20trainees,%20dates,%20and%20other%20notes.%20We%20will%20be%20in%20touch%20:)`;
                                        } else {
                                            if (isCloud) {
                                                ReactGA.event({
                                                    category: "training",
                                                    action: "click_on_bookseat_public",
                                                    label: "",
                                                })
                                            }
                                            handleButtonClick();
                                        }
                                    };
                                    const buttonStyle = {
                                        backgroundColor: row.originalPrice === "-" ? "rgba(251, 71, 160, 0.2)" : isSlotAvailable ? 'rgba(255, 132, 68, 0.2)' : "#1a1a1a",
                                        color: row.originalPrice === "-" ? "#FB47A0" : isSlotAvailable ? "#FF8444" : "#f86a3e",
                                        borderRadius: 50,
                                        width: 215,
                                        height: 52,
                                        marginTop: 30,
                                        fontSize: isSlotAvailable ? 25 : 20,
                                        border: isSlotAvailable ? "none" : "1px solid #f86a3e", // Initial border style
                                        textTransform: "none",
                                        boxShadow: 'none',
                                        transition: 'border-color 0.3s ease',
                                    };

                                    const hoverStyle = {
                                        border: `1px solid #FF8444`, // Border color on hover
                                    };

                                    return (
                                        <Card key={index} style={{ marginBottom: 20, }}>
                                            <CardContent>
                                                <Typography variant="h6">{row.courseName}</Typography>
                                                {/* Other content */}
                                                <Typography variant="subtitle1" style={{ fontSize: 25 }}>Date: {row.time}</Typography>
                                                <Typography variant="subtitle1" style={{ fontSize: 25 }}>Language: {row.language}</Typography>
                                                <Typography variant="subtitle1" style={{ fontSize: 25 }}>Timezone: {row.timezone}</Typography>
                                                <Typography variant="subtitle1" style={{ fontSize: 25, display: "flex", alignItems: "center" }}>Cost: <Typography style={{ fontSize: 20, textDecoration: 'line-through', marginLeft: 10, marginRight: 10, display: "flex" }}>{row.originalPrice}</Typography>{row.discountedPrice}</Typography>
                                                <Button
                                                    onClick={(e) => {
                                                        //console.log("Row price: ", row.originalPrice)
                                                        //if (row.originalPrice === "-") {
                                                        //	window.open("/contact", "_blank")
                                                        //} else {
                                                        handleClick(e)
                                                        //}
                                                    }}
                                                    style={{
                                                        ...buttonStyle,
                                                        ...(isSlotAvailable ? {} : hoverStyle), // Apply hoverStyle only when slot is not available
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {row.originalPrice === "-" ? "Contact Us" : isSlotAvailable ? "Book your seat" : "Join the waiting list"}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        :
                        <Grid container style={{ justifyContent: isMobile ? null : "center" }}>
                            <Grid item style={{ width: "auto", flexDirection: "no-wrap", border: "1px solid #494949", height: "fit-content", padding: 20, fontSize: '16px', color: '#ffffff', backgroundColor: '#1A1A1A', borderRadius: '16px', }}>
                                <TableContainer style={{ boxShadow: "none", display: "flex", maxWidth: isMobile ? 300 : 1000, minWidth: isMobile ? 300 : 1000 }} component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow style={{ backgroundColor: "#1A1A1A", }}>
                                                <TableCell align='left' style={{ fontSize: 24 }}>Course Name</TableCell>
                                                <TableCell align="center"><CalendarMonthOutlinedIcon /></TableCell>
                                                <TableCell align="center"><CategoryIcon /></TableCell>
                                                <TableCell align="center"><UpdateOutlinedIcon /></TableCell>
                                                <TableCell align="center"><CastForEducationIcon /></TableCell>
                                                <TableCell align="center"><MonetizationOnOutlinedIcon /></TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => {
                                                const date = row.time;
                                                const name = row.courseName;
                                                const availableSlots = availableSlotsPerDate[date] || 0;
                                                const isSlotAvailable = availableSlots > 0;
                                                const handleClick = () => {
                                                    if (row.originalPrice === "-") {
                                                        if (isCloud) {
                                                            ReactGA.event({
                                                                category: "training",
                                                                action: "click_on_bookseat_private",
                                                                label: "",
                                                            })
                                                        }
                                                        window.location.href = `mailto:shuffle-support@shuffler.io?subject=Request%20for%20Private%20Training&body=Please%20share%20details%20for%20training%20such%20as%20company%20details,%20number%20of%20trainees,%20dates,%20and%20other%20notes.%20We%20will%20be%20in%20touch%20:)`;
                                                    } else {
                                                        if (isCloud) {
                                                            ReactGA.event({
                                                                category: "training",
                                                                action: "click_on_bookseat_public",
                                                                label: "",
                                                            })
                                                        }
                                                        handleButtonClick();
                                                    }
                                                };
                                                return (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { border: index === 0 ? 'none' : '' }, }} style={{ borderRadius: 8, height: row.originalPrice === "-" ? null : 200, }}>
                                                        <TableCell align='left' style={{ fontSize: 16, fontWeight: 600, backgroundColor: "#1A1A1A", display: "flex", height: row.originalPrice === "-" ? null : 200, justifyContent: row.originalPrice === "-" ? "center" : "center", flexDirection: "column", alignItems: "start" }}>
                                                            {name}
                                                            <Typography style={{ marginTop: 10, fontSize: 16, textTransform: "capitalize", color: row.originalPrice === "-" ? "#FB47A0" : "#FF8444", width: 200 }}>
                                                                {row.originalPrice === "-" ? "Private Course" : "Public Course"}
                                                                {/* {row.originalPrice === "-" ? "Private Course" : (date === "Aug 26 - Aug 27" ? "" : "Public Course")} */}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: 18, width: 130 }}>{date}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 18 }}>{row.language}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 18, width: 100 }}>{row.timezone}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: row.instructor === "TBD" ? 18 : 18 }}>{row.instructor}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 18 }}>
                                                            <span style={{ textDecoration: 'line-through', display: "flow", fontSize: 14 }}>{row.originalPrice}</span>
                                                            <span style={{ fontWeight: 600 }}>{row.discountedPrice}</span>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {/* <Button
                                                                onClick={handleClick}
                                                                style={{
                                                                    backgroundColor: row.originalPrice === "-" ? "rgba(251, 71, 160, 0.2)" : isSlotAvailable ? 'rgba(255, 132, 68, 0.2)' : "#1a1a1a",
                                                                    color: row.originalPrice === "-" ? "#FB47A0" : isSlotAvailable ? "#FF8444" : "#f86a3e",
                                                                    borderRadius: 50,
                                                                    width: 162,
                                                                    height: 40,
                                                                    fontSize: isSlotAvailable ? 16 : 13,
                                                                    border: isSlotAvailable ? null : "1px solid #f86a3e",
                                                                    textTransform: "none",
                                                                    boxShadow: 'none',
                                                                    transition: 'border-color 0.3s ease', // Add transition for smooth hover effect
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{
                                                                    '&:hover': {
                                                                        border: `1px solid ${row.originalPrice === "-" ? "#FB47A0" : isSlotAvailable ? '#FF8444' : '#f86a3e'}`, // Change border color on hover
                                                                    },
                                                                }}
                                                            >
                                                                {row.originalPrice === "-" ? "Contact Us" : isSlotAvailable ? "Book your seat" : "Join the waiting list"}
                                                            </Button> */}
                                                            <Link to="https://book.stripe.com/eVa2bg9DU8dsces4gj" target='_blank'>
                                                                <Button
                                                                    onClick={(e) => {
                                                                        // setShowContactForm(true)
                                                                        // setTrainingRequest(row.originalPrice === "-" ? "Private_Training" : "Public_Training")
                                                                    }
                                                                    }
                                                                    // style={{
                                                                    style={{
                                                                        backgroundColor: row.originalPrice === "-" ? "rgba(251, 71, 160, 0.2)" : isSlotAvailable ? 'rgba(255, 132, 68, 0.2)' : "#1a1a1a",
                                                                        color: row.originalPrice === "-" ? "#FB47A0" : isSlotAvailable ? "#FF8444" : "#f86a3e",
                                                                        borderRadius: 50,
                                                                        width: 162,
                                                                        height: 40,
                                                                        marginTop: 20,
                                                                        fontSize: isSlotAvailable ? 16 : 13,
                                                                        border: isSlotAvailable ? "none" : "1px solid #f86a3e",
                                                                        textTransform: "none",
                                                                        boxShadow: 'none',
                                                                        transition: 'border-color 0.3s ease',
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                   {row.originalPrice === "-" ? "Contact us" : "Register"}
                                                                </Button>
                                                            </Link>
                                                            <Dialog open={showContactForm} onClose={() => { setShowContactForm(false); setFirstname(""); setEmail(""); setMessage("") }} PaperProps={{ sx: { borderRadius: theme?.palette?.DialogStyle?.borderRadius,
                                                                    border: theme?.palette?.DialogStyle?.border,
                                                                    minWidth: "600px",
                                                                    minHeight: "320px",
                                                                    fontFamily: theme?.typography?.fontFamily,
                                                                    backgroundColor: theme?.palette?.DialogStyle?.backgroundColor,
                                                                    zIndex: 1000,
                                                                    '& .MuiDialogContent-root': {
                                                                    backgroundColor: theme?.palette?.DialogStyle?.backgroundColor,
                                                                    },
                                                                    '& .MuiDialogTitle-root': {
                                                                    backgroundColor: theme?.palette?.DialogStyle?.backgroundColor,
                                                                    },
                                                                    '& .MuiDialogActions-root': {
                                                                    backgroundColor: theme?.palette?.DialogStyle?.backgroundColor,
                                                                    },}}}>
                                                                <DialogContent>
                                                                    <Typography style={{ fontSize: 24, color: "#FFFFFF", fontWeight: 600, textAlign: "center" }}>Contact Us</Typography>
                                                                    <Typography style={{ fontSize: 16, color: "#FFFFFF", textAlign: "center", marginTop: 10 }}>Please share details for training such as company details, number of trainees, dates, and other notes. We will be in touch.</Typography>
                                                                    <DialogActions style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", marginTop: 20, }}>
                                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Name"
                                                                                    defaultValue=""
                                                                                    variant="outlined"
                                                                                    style={{ width: 250, margin: 5 }}
                                                                                    required
                                                                                    onChange={(e) => setFirstname(e.target.value)}
                                                                                />
                                                                                <TextField
                                                                                    id="outlined-multiline-static"
                                                                                    label="Email"
                                                                                    multiline
                                                                                    defaultValue=""
                                                                                    variant="outlined"
                                                                                    style={{ width: 250, margin: 5 }}
                                                                                    required
                                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                                />
                                                                            </div>
                                                                            <TextField
                                                                                id="outlined-multiline-static"
                                                                                label="Message"
                                                                                defaultValue=""
                                                                                rows={4}
                                                                                multiline
                                                                                variant="outlined"
                                                                                style={{ width: 500, margin: "10px 0px" }}
                                                                                onChange={(e) => setMessage(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <Button
                                                                            onClick={() => {
                                                                                handleContactUs()
                                                                            }}
                                                                            style={{
                                                                                backgroundColor: name === "" || email === "" ? "#A9A9A9" : "#ff8544", // Set color when disabled
                                                                                color: "#1a1a1a",
                                                                                borderRadius: 4,
                                                                                width: 162,
                                                                                height: 40,
                                                                                marginTop: 20,
                                                                                fontSize: 16,
                                                                                textTransform: "none",
                                                                                boxShadow: 'none',
                                                                            }}
                                                                            disabled={name === "" || email === "" || message === ""}
                                                                            variant="contained"
                                                                            color="primary"
                                                                        >
                                                                            Contact Us
                                                                        </Button>
                                                                    </DialogActions>
                                                                </DialogContent>
                                                            </Dialog>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            {/* <TableRow >
                                                <Typography variant="body1" color="textSecondary" style={{ marginTop: 15,marginBottom: 15, fontSize:13 }}>
                                                    Times in other timezones coming soon
                                                </Typography>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Grid>}
                </div>
                {/* if you don't like then remove it, we can do something else there */}
                {/* <span style={backgroundStyle1} /> */}
                {/* if needed then we can also show the form to direct book or inquiery  */}
                <div ref={scrollFormRef} style={{ zIndex: 200, display: isMobile ? null : "flex", alignItems: "center", margin: "auto", justifyContent: "center", width: isMobile ? 350 : "100%" }}>
                    {/* <TrainingForm rows={rows} buttonBackground={buttonBackground} availableTimes={availableTimes} isMobile={isMobile} fromTrainingPage={fromTrainingPage} globalUrl={globalUrl} userdata={userdata} isCloud={isCloud} /> */}
                    <div style={{ marginTop: 100, zIndex: 10, marginLeft: isMobile ? null : 32 }}>
                        <Typography style={{ fontSize: 24, textAlign: isMobile ? "center" : null, color: "#F1F1F1" }}>Shuffle’s support team is always here for any questions or further information.</Typography>
                        <Typography style={{ fontSize: 26, color: "#F1F1F1", flexDirection: "column", marginTop: 10, textAlign: "center" }}>Reach us on the<Link to="/contact" style={{ textDecoration: "none", marginTop: 10, marginLeft: 10, fontSize: 26, color: "#FF8444", width: 250 }}>contact page</Link>.</Typography>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Trainingcourses;
